.new-user {
  margin-right: 5px;
  color: var(--ion-color-secondary);
  text-transform: capitalize;
  font-family: franco-manca-bold !important;
}

.web #main > ion-content.route-login > .ion-padding > .absolute-content {
  overflow-y: auto;
}

.web .route-login .page-secondary-header {
  display: block;
  top: var(--ion-padding);
}
.web .route-login .page-secondary-header::after {
  display: none;
}

.web .route-login .page-secondary-header + * {
  margin-top: 60px;
}
