@import url('./index.css');

.web * {
  scrollbar-width: thin !important;
  scrollbar-color: var(--okx-scrollbar-color);
}

.web ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.web ::-webkit-scrollbar-track {
  background: var(--okx-scrollbar-track-color);
  border-radius: 0;
}

.web ::-webkit-scrollbar-thumb {
  background: var(--okx-scrollbar-handle-color);
  border-radius: 0;
}
.web .web-only {
  display: block;
}

.web .mobile-only {
  display: none;
}


.web .static ion-toolbar.primary-toolbar {
  --background: transparent none;
}

.web .mbsc-select-input {
  cursor: pointer;
}

.web .mbsc-select-input[disabled],
.web .mbsc-sel-hdn[disabled] + .mbsc-select-input {
  cursor: default;
}

.web .static.route-register + .dynamic-header {
  display: none;
}

.web .restaurant-card ion-card-content ion-button:last-of-type {
  margin: 0;
}

@media screen and (max-width: 1200px) {
  .web .terms-modal .modal-wrapper{
  --width:var(--okx-bsmodal-width-md);
  }
  .web
    #main
    > ion-content:not(.route-order):not(.route-dashboard):not(.route-delivery-options):not(.route-delivery-address-check):not(.route-locations):not(.route-refer-a-friend):not(.route-pick-up-point-check):not(.route-history)
    > .no-padding,
  .web #main > ion-content > .ion-padding {
    width: var(--okx-bsmodal-width-md);
    margin-left: calc(var(--okx-bsmodal-width-md) / -2);
  }
  .web .ion-page > ion-content::before, .web .ion-page > ion-content::after{
    width: calc((95% - var(--okx-bsmodal-width-md)) / 2);

  }
  .web .restaurant-card,
  .web #main > ion-content.route-history > .no-padding,
  .web .ion-padding,
  .web #main > ion-content.route-history > .no-padding,
  .web .raf-card,
  .web .delivery-options-card,
  .web #main > ion-content.route-loyalty > .no-padding,
  .web .click-collect-layout {
    width: var(--okx-bsmodal-width-md);
  }
}

@media screen and (max-width: 960px) {
  .web .terms-modal .modal-wrapper{
    --width:var(--okx-bsmodal-width-sm);
    }
  .web
    #main
    > ion-content:not(.route-order):not(.route-dashboard):not(.route-delivery-options):not(.route-delivery-address-check):not(.route-locations):not(.route-refer-a-friend):not(.route-pick-up-point-check):not(.route-history)
    > .no-padding,
  .web #main > ion-content > .ion-padding,
  .web #main > ion-content.route-history > .no-padding,
  .web .ion-padding,
  .web #main > ion-content.route-history > .no-padding,
  .web .raf-card {
    width: var(--okx-bsmodal-width-sm);
    margin-left: calc(var(--okx-bsmodal-width-sm) / -2);
  }
  .web .ion-page > ion-content::before, .web .ion-page > ion-content::after{
    width: calc((950% - var(--okx-bsmodal-width-sm)) / 2);

  }
  .web .restaurant-card,
  .web .delivery-options-card,
  .web #main > ion-content.route-loyalty > .no-padding,
  .web .click-collect-layout {
    width: var(--okx-bsmodal-width-sm);
  }
}

@media screen and (max-width: 991px) {
  .web .collapse-drawer-icon {
    display: block;
    display: none;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0%, -50%);
    cursor: pointer;
    font-size: 25px;
  }
}

/* responsive end */

/* big screen override */

@media screen and (min-width: 992px) {
  .web ion-header:not(.route-login) + ion-toolbar.dynamic-header,
  .web ion-header:not(.route-login) + .content-spinner + ion-toolbar.dynamic-header {
    top: 16px;
    width: 100px;
  }
}

.web .mobile-only {
  display: none;
}
.web ion-radio {
  border: none;
}

.web #main > ion-content .ion-padding,
.web #main > ion-content .no-padding {
  user-select: none;
}

