.order-content-wrapper {
  position: relative;
}

.order-content-wrapper .basket-item-wrapper .basket-item-service-charge-row {
  cursor: pointer;
}
.order-content-wrapper .basket-item-wrapper-0 ion-row:first-child {
  border-top: 0;
}
.order-content-wrapper ion-row {
  border-top: 1px solid var(--ion-color-light);
  padding: 10px 20px 10px 15px;
}

.web .order-summary-sidebar .order-content-wrapper .subtotal-bordered-bottom {
  border-top: 1px solid var(--okx-border-color);
}

.total-bordered-bottom {
  border-bottom: 2px solid var(--okx-border-color);
}

ion-col.grow {
  flex-grow: 3.5;
}

.order-content-wrapper .basket-item-wrapper + ion-row > ion-col > ion-text > span {
  font-weight: normal;
}

.add-items-btn {
  font-size: 10px;
  height: unset;
  margin: 0 !important;
  --padding-top:5px;
  --padding-bottom:5px;
  height: unset !important;
  height: 20px;
  min-height: 20px;

}

.add-items-btn.ion-color-black {
  --ion-color-base: var(--ion-color-secondary);
}

.total-bordered-bottom {
  border-bottom: 1px solid var(--ion-color-black);
}

.total-bordered-top {
  border-top: 1px solid var(--ion-color-black);
}

.subtotal-bordered-bottom {
  border-bottom: 1px solid var(--okx-border-color);
}

ion-col.grow {
  flex-grow: 3.5;
}

ion-modal.modal-classic.remove-apply-voucuher {
  --width: 70%;
  --height: 20%;
  --border-radius: 10px;
}

.web ion-modal.modal-classic.remove-apply-voucuher {
  --width: 500px;
  --height: 20vh;
  --min-height: 110px;
}

.web ion-modal.modal-classic.remove-apply-voucuher .modal-classic-header {
  height: 100%;
  display: grid;
  place-content: center;
  text-transform: uppercase;
}


.basket-items-wrapper {
	position: relative;
}
.basket-items-wrapper > div {
	margin-bottom: 20px;
	display: flex;
  position: relative;
  padding: 0 0 20px 0;
}
.basket-items-wrapper > div:last-of-type{
  margin-bottom: 0;

}
.basket-items-wrapper > div::after{
  left: 0;
  bottom: -2px;
  position: absolute;
  width: 100%;
  height: 5px;
  background: url(../../assets/images/dash.svg) 50% / auto 100% repeat !important;
  background-repeat: repeat-x;
  content: '';
  z-index: 10;
}

.basket-items-wrapper .order-item-image {
	height: 70px;
	width: 70px;
	border-radius: 15px;
  overflow: hidden;
	background-size: cover;
	margin-right: 10px;
	background-position: center;
	background-repeat: no-repeat;
	background-color: var(--ion-color-gray);
	position: relative;
}

.basket-items-wrapper .order-item-image::before {
	content: '';
	height: 100%;
	width: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background-color: rgba(0, 0, 0, 0.05);
}
.basket-items-wrapper .item-content-wrapper {
	flex: 1;
}
.basket-items-wrapper .item-content-actions {
	margin-top: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.basket-items-wrapper .item-content-actions .incrementer ion-button ion-icon {
	color: var(--ion-color-primary-contrast);
}
.subchoice-price{
  margin-left: 5px;
  display: inline-block;
}
.order-content-wrapper.is-loading{
  pointer-events: none;
}
.order-content-wrapper.is-loading .basket-items-wrapper > div::after,.order-content-wrapper.is-loading .amount-values::before{
  background: url(../../assets/images/dash-animated.gif) 50% / auto 100% repeat !important;
}
