.allergens-wrapper ion-button {
  font-weight: 900 !important;
}

.allergens-wrapper .strong-text {
  margin-bottom: 10px;
  display: block;
}
.web .allergens-wrapper {
  padding: 20px !important;
}
