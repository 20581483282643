.okx-password-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.okx-password-wrapper .okx-password-holder {
  flex: 1;
}

.okx-password-wrapper ion-button {
  flex: 0;
}

ion-button.button-small {
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  font-size: var(--okx-small-text-font-size) !important;
}