.route-scan video {
  flex: 1;
}
.route-scan .absolute-content {
  display: flex;
  flex-direction: column;
}
.route-scan button {
  width: 100%;
}
.route-scan .ion-padding {
  height: 100%;
}

#qr-code-scanner__dashboard_section > *{
  margin-top: 20px;

}