/* ion-content.route-delivery-options {
  width: 100%;
  height: calc(100vh - 79px);
  background-image: url(../../assets/images/backgrounds/main-bg.jpg);
  background-position: 50% 100%;
  background-size: cover;
  background-repeat: no-repeat;
  overflow-y: hidden;
} */

/* ion-content.route-delivery-options::before {
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 85%;
  z-index: 0;
  background-image: linear-gradient(rgba(000, 000, 000, 1), rgba(000, 000, 000, 0));
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
} */

.delivery-options-card {
  margin: 0;
  height: 100%;
}

.delivery-options-card ion-card-title {
  font-size: var(--okx-title-font-size);
}

.delivery-options-menu {
  /* padding: 0 64px; */
  padding: 0 24px;
}

.delivery-options-menu ion-item {
  --ion-color-contrast: var(--ion-color-white) !important;
  --color: var(--ion-color-contrast);
  --background: var(--ion-color-secondary);
  --min-height: 32px;
  margin-bottom: 20px;
  border-radius: calc(var(--okx-small-radius) - 2px);
  cursor: pointer;
}

.delivery-options-menu ion-label {
  font-weight: normal;
  text-align: center;
}

.delivery-options-menu > ion-list > ion-item > ion-label {
  padding-top: 2px;
  margin-right: 0;
  font-weight: bold !important;
}

.web #main > ion-content.route-delivery-options {
  background-size: cover;
}

.web ion-content.route-delivery-options::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: -1;
}

.order-description {
  font-size: 9px;
}

.delivery-options-content {
  min-height: 160px;
}

.delivery-options-button {
  margin: 20px;
}

.delivery-options-content {
  padding: 0 var(--ion-padding) !important;
}
.delivery-options-content ion-item {
  border-bottom: 1px solid var(--ion-color-light);
}
