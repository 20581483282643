#main > ion-content {
  z-index: 5;
}

ion-content.ion-color-transparent {
  --background: transparent;
}
.ion-page {
  min-width: 285px;
}
.app-footer {
  display: flex;
  background-color: var(--okx-background-color-dark);
  justify-content: space-between;
  height: 80px;
}
.app-footer::before {
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  height: 5px;
  background: url(../../assets/images/dash.svg) 50% / auto 100% repeat !important;
  background-repeat: repeat-x;
  content: '';
  z-index: 10;
}
.app-footer > * {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.web .app-footer {
  display: none;
}
