.contact-subtitle {
  color: var(--ion-color-secondary) !important;
  text-transform: uppercase;
  font-family: franco-manca-bold !important;
  display: block;
}

.comment-wrapper {
  background: var(--okx-background-color-dark);
  border-radius: var(--okx-box-radius);
  padding: var(--ion-padding);
}
