.selected-restaurant-wrapper .swiper {
  flex: unset;
  height: unset;
}
.selected-restaurant-wrapper * {
  font-family: primary-regular !important;
}
.selected-restaurant-wrapper {
  margin: 0 var(--ion-padding) var(--ion-padding);
}
.selected-restaurant-wrapper .title,
.selected-restaurant-wrapper .subtitle,
.selected-restaurant-wrapper .normal-text {
  text-align: start;
  display: block;
  color: #212121 !important;
}
.selected-restaurant-wrapper .subtitle {
  font-size: 16px;
}
.restaurant-station-wrapper img {
  height: 30px;
  width: 30px;
  margin-right: 15px;
}
.restaurant-links-wrapper img {
  height: 40px;
  width: 40px;
  margin-right: 15px;
}

.restaurant-station-wrapper,
.restaurant-links-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.search-input {
  text-align: left;
}

.all-restaurants-wrapper {
  padding: var(--ion-padding) var(--ion-padding) 0;
}

.all-restaurants-wrapper ion-button {
  margin-top: var(--ion-padding);
}

.all-restaurants-wrapper .native-input {
  font-family: var(--ion-font-family) !important;
}

.restaurant-name {
  font-family: var(--ion-font-family) !important;
  margin-bottom: 15px;
}

.selected-restaurant-wrapper .swiper {
  height: 320px;
}
.selected-restaurant-wrapper .swiper-slide{
  height: 300px;
}
.selected-restaurant-wrapper .swiper-slide img{
  height: 300px;
  object-fit: contain;
}