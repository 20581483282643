.no-table-alert .alert-button-group {
  align-items: center !important ;
  display: flex;
  justify-content: center !important;
}

.no-table-alert .alert-button-role-confirm {
  color: var(--ion-color-tertiary);
}

.no-table-alert .alert-button-role-cancel {
  color: var(--ion-color-dark);
  text-decoration: underline;
  padding-top: 20px;
}
