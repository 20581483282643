.route-check-table > .ion-padding {
  height: 100%;
}
.progress-bar-wrapper {
  width: 100%;
  height: 15px;
  background-color: var(--ion-color-light);
  border-radius: 5px;
  overflow: hidden;
}


.progress-bar {
  transition:width 1s linear;
  background: repeating-linear-gradient(
    40deg, 
    #df3a3a 0px, 
    #df3a3a 5px, 
    var(--ion-color-secondary) 6px, 
    var(--ion-color-secondary) 10px, 
    #df3a3a 11px, 
    #df3a3a 15px, 
    var(--ion-color-secondary) 16px, 
    var(--ion-color-secondary) 20px
);
  height: 15px;
  width: 1%;
}
.progress-bar-title{
  font-size: var(--okx-subtitle-font-size);
  margin-bottom: 5px;
  display: block;
  text-align: center;
}