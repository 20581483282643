.tutorial-slider .tutorial-header img {
  width: 70px;
  height: 80px;
}
.tutorial-modal ion-slides {
  height: 100%;
}
.tutorial-modal ion-slide {
  align-items: flex-start;
}
ion-modal.tutorial-modal.modal-classic {
  --width: 80%;
  --height: 70%;
}
.tutorial-slider {
  height: 85%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.tutorial-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.tutorial-content img {
  max-height: 130px;
  margin: 0 auto;
}
.tutorial-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tutorial-header > * {
  flex: 1;
}
.tutorial-header > img {
  flex: unset;
}
.tutorial-button {
  position: absolute;
  bottom: 25px
}

.tutorial-modal .slider-wrap ,
.tutorial-modal .swiper-initialized {
  height: 100%;
}

.swiper-pagination-bullet {
  background-image: url('../../assets/images/icons/non_selected_paginator.svg');
}

.swiper-pagination-bullet-active {
  background: var(--bullet-background) !important;
  background-image: url('../../assets/images/icons/selected_pagniator.svg') !important;
}