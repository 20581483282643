ion-slide {
  --bullet-background: var(--ion-color-primary);
  --bullet-background-active: var(--ion-color-primary);
}
.route-homepage ion-slides {
  height: 200px;
  margin-top: -50px;
  font-family: franco-manca-bold !important;
}
.route-homepage ion-slide {
  min-height: unset;
}
.route-homepage .swiper-slide {
  font-size: 15px;
}
.homepage-banner-swipper {
  display: flex;
  align-items: center;
}
.homepage-banner-swipper img {
  width: 100px;
  height: 110px;
}
.rewards-wrapper {
  background-image: url('../../assets/images/backgrounds/border-stamps.png');
  width: 100%;
  height: 225px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-position: center;
}
.rewards-wrapper > div {
  background-image: url('../../assets/images/backgrounds/stamp2_not_selected.svg');
  height: 100%;
  width: 100%;
  background-position: center -5px;
  background-repeat: no-repeat;
}
.rewards-wrapper > div.checked {
  background-image: url('../../assets/images/backgrounds/stamp2_selected_v2.svg');
  background-size: 99%;
  background-position: -10% 80%;
}
.homepage-actions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
}
.homepage-actions ion-button {
  width: 100%;
}
.homepage-actions ion-button:first-of-type {
  grid-column: span 2;
}
.panel-wrapper {
  border-radius: var(--okx-box-radius);
  height: 260px;
  width: 100%;
  padding: 15px;
  background-color: var(--okx-background-color-dark);

  overflow: hidden;
  clip-path: polygon(0% 97%, 0 1%, 23% 1%, 60% 0, 100% 0%, 100% 83%, 100% 100%, 25% 100%);
}
.panel-wrapper > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.panel-wrapper .subtitle {
  font-size: 16px;
  font-family: franco-manca-bold !important;
  text-transform: uppercase;
  color: var(--ion-color-secondary);
  margin-bottom: 5px;
}

.content-panel-text * {
  text-transform: uppercase;
  font-family: primary-regular !important;
}
.content-panel-text p {
  font-size: 16px;
  margin: 0;
}
.content-panel-text small {
  font-size: 14px;
  line-height: 16px;
}
.content-panel-text a {
  color: var(--ion-color-secondary);
  text-decoration: underline;
}
.panel-type-1,
.panel-type-4,
.panel-type-5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.panel-type-1 > div,
.panel-type-4 > div,
.panel-type-5 > div {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}
.panel-type-1 > img {
  width: 100%;
  height: 110px;
  object-fit: contain;
}
.panel-type-4 > img {
  width: 100%;
  height: 140px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}
.panel-type-4,
.panel-type-5 {
  padding: 0;
}
.panel-type-4 > div {
  padding: 10px;
}
.panel-type-5 > img {
  height: 40%;
  object-fit: cover;
  width: 100%;
  border-radius: 10px 10px 0 0;
}
.panel-type-5 > div {
  height: 60%;
  padding: 10px;
}
.panel-type-2 {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
}
.panel-type-2 > div {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}
.panel-type-2 > img {
  width: 100%;
  height: 80px;
  object-fit: contain;
}

.panel-type-3 {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
}
.panel-type-3 > div {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}
.panel-type-3 > img {
  width: 100%;
  height: 110px;
  object-fit: contain;
}

.panel-type-6 {
  display: flex;
  padding: 0;
}
.panel-type-6 > div {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 10px;
}
.panel-type-6 > img {
  width: 60%;
  height: 100%;
  border-radius: 10px 0 0 10px;
  object-fit: cover;
}

.rewards-btn-indicator {
  position: absolute;
  height: 40px;
  width: 30px;
  background-color: var(--ion-color-dark);
  color: var(--ion-color-dark);
  letter-spacing: normal;
  font-family: franco-manca-bold !important;
  font-size: 15px;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -10px;
  top: -20px;
  border-radius: 50%;
  clip-path: polygon(30% 0%, 76% 0, 100% 17%, 100% 74%, 85% 98%, 20% 100%, 0 79%, 0 16%);
}
.rewards-btn-indicator.rewards-btn-two-digits {
  width: 40px;
  height: 50px;
  top: -25px;
}
.rewards-btn-indicator.rewards-btn-three-digits {
  width: 60px;
  height: 60px;
  top: -25px;
}
.rewards-btn-indicator::before {
  content: '';
  background: var(--ion-color-white);
  height: 80%;
  width: 80%;
  position: absolute;
  top: 10%;
  right: 10%;
  border-radius: 50%;
  clip-path: polygon(30% 0%, 76% 0, 100% 17%, 100% 74%, 85% 98%, 20% 100%, 0 79%, 0 16%);
  z-index: -1;
}
.rewards-btn-wrapper {
  position: relative;
}
.intro-slider .normal-text {
  text-align: center;
  font-family: franco-manca-bold !important;
  font-size: 11px;
}
.rewards-wrapper > div.checked:nth-of-type(1) {
  transform: rotate(-183deg);
}
.rewards-wrapper > div.checked:nth-of-type(2) {
  transform: rotate(-10deg);
}
.rewards-wrapper > div.checked:nth-of-type(3) {
  transform: rotate(-7deg);
}
.rewards-wrapper > div.checked:nth-of-type(4) {
  transform: rotate(6deg);
}
.rewards-wrapper > div.checked:nth-of-type(5) {
  transform: rotate(2deg);
}
.rewards-wrapper > div.checked:nth-of-type(6) {
  transform: rotate(10deg);
}

.you-are-offline-banner {
  width: 100%;
}

.route-homepage .swiper-initialized {
  padding-bottom: 10px;
}

.route-homepage .swiper-pagination{
  position:unset !important ;
}

.swiper-pagination-bullet {
  background-image: url('../../assets/images/icons/non_selected_paginator.svg');
}

.swiper-pagination-bullet-active {
  background: var(--bullet-background) !important;
  background-image: url('../../assets/images/icons/selected_pagniator.svg') !important;
}