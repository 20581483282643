.okx-validate-input-wrapper {
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
}

.okx-validate-input-wrapper .okx-validate-input-holder {
  flex: 1 1 auto;
  /* padding-bottom: 5px; */
}

.okx-validate-input-wrapper .okx-validate-input-holder ion-datetime {
  --padding-start: 0;
  --padding-top: 15px;
}
.okx-validate-input-wrapper .okx-validate-input-holder p {
  /* margin: 21px 0 0 0; */
  display: flex;
  /* padding-left: 2px; */
  color: inherit;
}

.okx-validate-input-wrapper .okx-validate-input-holder ion-label,
.okx-validate-input-holder > .date-picker-wrapper .date-picker-modal-label {
}

.okx-validate-input-holder > .date-picker-wrapper .date-picker-modal-label {
  font-size: 11px;
}

.okx-validate-input-wrapper ion-button {
  flex: 1 0 auto;
  height: 30px !important;
  width: 30px;
  margin-top: 0;
}

.date-picker-wrapper.modal .data-picker-input {
  padding-top: 15px;
}

.date-picker-wrapper.modal ion-label {
  font-size: 8px;
}

.date-picker-modal-label {
  font-size: 9px;
  color: var(--ion-color-dark);
  font-weight: normal;
  /* margin-left: 2px; */
  transition: 0.1s ease-in;
}
