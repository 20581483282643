.web #main > ion-content.route-faq > .ion-padding {
  padding-right: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.web .route-faq .ion-padding > div {
  overflow-y: auto;
  max-height: 100%;
  padding-right: 10px;
}
.route-faq .red_text_head,
.faq-modal .red_text_head {
  color: var(--ion-color-secondary);
  font-family: franco-manca-bold !important;
}
.route-faq .welcome_text,
.faq-modal .welcome_text {
  text-align: left;
}
.route-faq .welcome_text a,
.faq-modal .welcome_text a {
  color: #3880ff;
}

ion-modal.modal-classic.faq-modal {
  --height: 100%;
  --width: 100%;
}
ion-modal.modal-classic.faq-modal .modal-wrapper {
  border-radius: 0 !important;
}
