.terms-modal .modal-wrapper {
  --width: 100%;
  --height: 100%;
  border-radius: 0px !important;
}

.terms-modal .modal-classic-content {
  padding-top: 0px !important;
}



.web .terms-modal .modal-wrapper{
  --width:var(--okx-bsmodal-width-lg);
  --height:85%;
  border-radius:var(--okx-box-radius) !important;
  }