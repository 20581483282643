.route-dashboard .swiper-wrapper * {
  font-family: franco-manca-bold !important;
  text-align: center;
}
.route-dashboard .swiper-pagination {
  position: unset;
  padding-bottom: 10px;
}
.route-dashboard swiper-slide {
  height: 200px;
  margin-top: -50px;
}
.route-dashboard ion-slide {
  min-height: unset;
}
.route-dashboard .swiper-slide {
  font-size: 15px;
}
.route-homepage .swiper-initialized {
  padding-bottom: 10px;
}
.animated-header {
  margin: auto;
  display: block;
  height: 150px;
  object-fit: contain;
}

.route-dashboard .absolute-content {
  padding: 50px;
}

.route-dashboard .dashboard-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.route-dashboard .dashboard-wrapper .app-version {
  margin: 0 auto;
  text-align: center;
}

.swiper-pagination-bullet {
  background-image: url('../../assets/images/icons/non_selected_paginator.svg');
}

.swiper-pagination-bullet-active {
  background: var(--bullet-background) !important;
  background-image: url('../../assets/images/icons/selected_pagniator.svg') !important;
}
