.web .previous-order-content img {
  display: none;
}
.previous-order-content img {
  transform: scaleX(-1);
}
.previous-order-content {
  margin-top: 60px;
}
.web .previous-order-content {
  margin-top: 0;
}
.history-item {
  position: relative;
  margin-bottom: 10px;
}
.previous-order-content {
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
}

.previous-order-content > div {
  flex: 1;
}
.web .route-previous-orders .absolute-content {
  padding: var(--ion-padding) 0;
}
.web .previous-order-content > *{
  padding: 0 var(--ion-padding);
}
.route-previous-orders .rounded-btn {
  font-size: var(--okx-small-text-font-size) !important;
  margin: 0 !important;
  min-height: 30px;
  height: 30px;
}
.route-previous-orders .rounded-btn svg{
  stroke: var(--ion-color-secondary-contrast);
  height: var(--okx-small-text-font-size);
  width: var(--okx-small-text-font-size);
  margin-right: 5px;
}
.web .route-previous-orders .page-secondary-header{
  display: block;
  top: var(--ion-padding);
}
.web .route-previous-orders .page-secondary-header::after{
 display: none;
}

.web .route-previous-orders .page-secondary-header + *{
  margin-top: 60px;
}