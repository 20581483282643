ion-toolbar.primary-toolbar {
  --min-height: 80px;
  --background: var(--okx-background-color-dark);
}
ion-toolbar.toolbar-disabled{
	pointer-events: none !important;
}
.image-button {
  flex: 1;
  background: transparent;
  --ripple-color: transparent;
  background: url(../../assets/images/logo.svg) no-repeat 50% 50% / auto 55%;
}
ion-header::after,
.header-md::after {
  left: 0;
  bottom: -2px;
  position: absolute;
  width: 100%;
  height: 5px;
  background: url(../../assets/images/dash.svg) 50% / auto 100% repeat !important;
  background-repeat: repeat-x;
  content: '';
  z-index: 10;
}

.web ion-toolbar.primary-toolbar .image-button{
  display: none;

}
.web ion-header{
  position: absolute;
}
.web ion-header::after, .web .header-md::after {
  display: none;
}


ion-button.basket-button {
	/* width: 45px; */
	height: 41px;
  --background:transparent !important
}

ion-button.basket-button > div > div > .badge,
ion-button.basket-button > div > div > .badge-small {
	position: absolute;
	bottom: 0;
	width: 20px;
	height: 20px;
	right: -4px;
	z-index: 1;
	padding: 4px 1px;
	font-size: 12px;
	border-radius: 60px;
}

ion-button.basket-button > div > div > .badge-small {
	width: 18px;
	height: 18px;
	right: -2px;
	padding: 2px 0 0 0;
}

ion-button.basket-button > div > .basket-icon-wrapper {
	position: relative;
}

ion-toolbar.primary-toolbar {
	--min-height: 80px;
	background-size: auto 35px;
	background-repeat: no-repeat;
	background-position: 50%;
}

ion-button.basket-button > div > div > ion-icon {
	font-size: 30px;
}