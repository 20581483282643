ion-menu {
  --background: var(--okx-background-color);
}
.close-menu {
  position: absolute;
  bottom: var(--ion-padding);
  right: var(--ion-padding);
}
.sidebar-link {
  font-size: 21px !important;
  font-family: secondary-regular !important;
  color: var(--ion-color-dark);
  display: block;
  cursor: pointer;
}
.sidebar-menu > ion-list > div {
  margin: 10px 5px;
}
.sidebar-menu > ion-list > div:first-of-type {
  margin-top: 0;
}
.sidebar-menu > ion-list > div:last-of-type {
  margin-bottom: 0;
}
.grayed-links .sidebar-link {
  color: var(--ion-color-gray);
}
.sidebar-menu > ion-list {
  padding: var(--ion-padding) 0 0;
  margin: var(--ion-padding) var(--ion-padding) 0;
  position: relative;
}
.sidebar-menu > ion-list:last-of-type {
  padding-bottom: var(--ion-padding);
}
.sidebar-menu > ion-list::before,
.sidebar-menu > ion-list::after {
  content: '';
  width: 50%;
  height: 3px;
  position: absolute;
  background-image: url(../../assets/images/backgrounds/sidebar-separator.svg);
  background-repeat: no-repeat;
}
.sidebar-menu > ion-list::before {
  left: 0;
  top: 0;
}
.sidebar-menu > ion-list:last-of-type::after {
  left: 0;
  bottom: 0;
}
.sidebar-user {
  margin: 40px var(--ion-padding) 0 !important;
  color: var(--ion-color-secondary) !important;
  font-size: 18px !important;
}
.sidebar-user-ios {
  margin: calc(10px + var(--safe-area-inset-top)) var(--ion-padding) 0 !important;
}
.web .small-drawer-header {
  padding: 15px 0 0 0;
}

.web .small-drawer-header ion-buttons {
  position: absolute;
  top: 1px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.web .small-drawer-header ion-button {
  --padding-top: 12px;
  --padding-end: 12px;
  --padding-bottom: 12px;
  --padding-start: 12px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  width: 48px;
  height: 48px;
}

.web .small-drawer-header ion-button ion-icon {
  font-size: 1.8rem;
  color: var(--okx-color-black);
}
.web .small-drawer-toolbar {
  --background: transparent;
}
.sidebar-auth {
  position: absolute;
  bottom: calc(var(--ion-padding) + 10px);
  left: var(--ion-padding);
}
ion-split-pane {
  --side-max-width: var(--okx-drawer-width);
  --side-min-width: var(--okx-drawer-width);
  --side-width: var(--okx-drawer-width);
  --border: 0 none;
}

.route-native-order-completed .drawer-menu,
.route-native-checkout .drawer-menu,
.route-native-card-add .drawer-menu,
.route-native-payment-result-view .drawer-menu,
.route-native-order-completed .sidebar-menu,
.route-native-checkout .sidebar-menu,
.route-native-card-add .sidebar-menu,
.route-native-payment-result-view .sidebar-menu
 {
  display: none;
}


.route-native-card-add .ion-padding,
.route-native-checkout .ion-padding,
.route-native-order-completed .ion-padding,
.route-native-payment-result-view .ion-padding,
.route-native-card-add.web .ion-padding,
.route-native-checkout.web .ion-padding,
.route-native-order-completed.web .ion-padding,
.route-native-payment-result-view.web .ion-padding{
  width: 100% !important;
  height: 100% !important;
  max-height: 100% !important;
  left: 0 !important;
  border-radius: 0 !important;
}
.route-native-card-add.web ion-header,
.route-native-checkout.web ion-header,
.route-native-order-completed.web ion-header,
.route-native-payment-result-view.web ion-header{
position: relative;
}
.route-native-card-add.web .static ion-toolbar:first-of-type,
.route-native-checkout.web .static ion-toolbar:first-of-type,
.route-native-order-completed.web .static ion-toolbar:first-of-type,
.route-native-payment-result-view.web .static ion-toolbar:first-of-type{
--background: var(--okx-background-color-dark);
}
.route-native-card-add.web ion-toolbar.primary-toolbar .image-button,
.route-native-checkout.web ion-toolbar.primary-toolbar .image-button,
.route-native-order-completed.web ion-toolbar.primary-toolbar .image-button,
.route-native-payment-result-view.web ion-toolbar.primary-toolbar .image-button{
display: flex;
}