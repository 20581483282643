.route-add-stamps > .ion-padding {
  min-height: var(--okx-puller-height);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.refer-fiend-code {
  background: #fff;
  border-radius: 15px;
  font-size: var(--okx-subtitle-font-size, 26px);
  margin-bottom: 20px;
  text-align: center;
}

.check-in-alert .alert-button {
  color: var(--ion-color-gray);
}
.route-add-stamps .small-subtitle {
  font-size: 14px;
}
