/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */


@font-face {
  font-family: 'primary-regular';
  src: url('../assets/fonts/AHAMONO-Regular.eot');
  src: url('../assets/fonts/AHAMONO-Regular.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/AHAMONO-Regular.woff2') format('woff2'),
    url('../assets/fonts/AHAMONO-Regular.woff') format('woff'),
    url('../assets/fonts/AHAMONO-Regular.ttf') format('truetype'),
    url('../assets/fonts/AHAMONO-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'secondary-regular';
  src: url('../assets/fonts/Maple-Medium.eot');
  src: url('../assets/fonts/Maple-Medium.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Maple-Medium.woff2') format('woff2'),
    url('../assets/fonts/Maple-Medium.woff') format('woff'),
    url('../assets/fonts/Maple-Medium.ttf') format('truetype'),
    url('../assets/fonts/Maple-Medium.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'franco-manca-bold';
  src: url('../assets/fonts/FrancoMancaBold.eot');
  src: url('../assets/fonts/FrancoMancaBold.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/FrancoMancaBold.woff2') format('woff2'),
    url('../assets/fonts/FrancoMancaBold.woff') format('woff'),
    url('../assets/fonts/FrancoMancaBold.ttf') format('truetype'),
    url('../assets/fonts/FrancoMancaBold.svg') format('svg');
  src: url('../assets/fonts/franco_manca_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #5a5c5e;
  --ion-color-primary-rgb: 90, 92, 94;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #5a5c5e;
  --ion-color-primary-tint: #5a5c5e;

  /** secondary **/
  --ion-color-secondary: #ed3d3e;
  --ion-color-secondary-rgb: 237, 61, 62;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #ed3d3e;
  --ion-color-secondary-tint: #ed3d3e;

  /** tertiary **/
  --ion-color-tertiary: #3880ff;
  --ion-color-tertiary-rgb: 56, 128, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #3880ff;
  --ion-color-tertiary-tint: #3880ff;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/

  --ion-color-dark: #000;
  --ion-color-dark-rgb: 0, 0, 0;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #000;
  --ion-color-dark-tint: #000;
  /** gray **/
  --ion-color-gray: #9b9b9b;
  --ion-color-light-gray: #9b9b9b;
  --ion-color-gray-rgb: 155, 155, 155;
  --ion-color-gray-contrast: #ffffff;
  --ion-color-gray-contrast-rgb: 255, 255, 255;
  --ion-color-gray-shade: #9b9b9b;
  --ion-color-gray-tint: #9b9b9b;

  /** greenlight **/
  --ion-color-greenlight: #c1d5ae;
  --ion-color-greenlight-rgb: 193, 213, 174;
  --ion-color-greenlight-contrast: #000000;
  --ion-color-greenlight-contrast-rgb: 0, 0, 0;
  --ion-color-greenlight-shade: #aabb99;
  --ion-color-greenlight-tint: #c7d9b6;

  /** light **/
  --ion-color-light: rgba(0, 0, 0, 0.08);
  --ion-color-light-rgb: 216, 218, 223;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d8dadf;
  --ion-color-light-tint: #d8dadf;

  /** superlight **/
  --ion-color-superlight: #f4f4f4;
  --ion-color-superlight-rgb: 244, 244, 244;
  --ion-color-superlight-contrast: #000000;
  --ion-color-superlight-contrast-rgb: 0, 0, 0;
  --ion-color-superlight-shade: #ebebeb;
  --ion-color-superlight-tint: #f9f9f9;

  /** white **/
  --ion-color-white: #fff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #222;
  --ion-color-white-contrast-rgb: 34, 34, 34;
  --ion-color-white-shade: #d7d8da;
  --ion-color-white-tint: #f5f6f9;

  /** black **/
  --ion-color-black: #515050;
  --ion-color-black-rgb: 17, 17, 17;
  --ion-color-black-contrast: #eee;
  --ion-color-black-contrast-rgb: 238, 238, 238;
  --ion-color-black-shade: #000;
  --ion-color-black-tint: #555;

  /** form **/
  --ion-color-form-label: #b0abab;
  --ion-color-form-label-dark: #515050;
  --ion-color-note: #cbc6c6;

  /** custom values **/
  --ion-color-title2: #1073d0;
  --ion-color-box-holder: transparent;
  --ion-separator-color: #e6e6e6;
  --ion-feedback-label: #727272;
  --ion-top-small: 5px;
  --ion-top-medium: 30px;
  --ion-feedback-star: #9ba1a5;

  /** original theme file colors **/
  --ion-headings-font-weight: 300;

  --ion-color-angular: #ac282b;
  --ion-color-communication: #8e8d93;
  --ion-color-tooling: #fe4c52;
  --ion-color-services: #fd8b2d;
  --ion-color-design: #fed035;
  --ion-color-workshop: #69bb7b;
  --ion-color-food: #3bc7c4;
  --ion-color-documentation: #b16be3;
  --ion-color-navigation: #6600cc;
  --ion-color-border: #ffc72a;

  --ion-padding: 25px;
  --ion-toolbar-min-height: 48px;

  --ion-card-radius: 12px;
  --ion-item-color: #999;
  --ion-item-background: transparent;
  --ion-text-color: var(--ion-color-primary);
  --ion-circle-color: rgb(232, 231, 233);

  /** fonts **/
  --ion-font-family: primary-regular;

  --ion-font-size: 14px;

  /* okx vars */
  --okx-background-color: #faf5f5;
  --okx-background-color-dark: #ebe6e6;

  /* font-sizes */
  --okx-title-font-size: 22px;
  --okx-subtitle-font-size: 20px;
  --okx-font-size: var(--ion-font-size);
  --okx-sectiontitle-font-size: 18px;
  --okx-note-font-size: 14px;
  --okx-error-font-size: 10px;
  --okx-small-text-font-size: 12px;
  --okx-big-label-font-size: 42px;

  /* smaller font sizes for desktop */

  /* font colors */
  --okx-default-text-color: var(--ion-color-primary);
  --okx-light-text-color: var(--ion-color-gray);

  --okx-drawer-max-width: 340px;
  --okx-small-drawer-max-width: 90px;
  --okx-drawer-width: var(--okx-drawer-max-width);
  --okx-sidebar-width: 360px;
  --okx-flex-min: 5px;
  --okx-flex-spacer: 20px;
  /* --okx-big-label-color: var(--ion-color-primary); */
  --okx-big-label-margin: 2px 0;
  --okx-big-label-padding: 0;
  /* --okx-title-color: var(--ion-color-primary); */
  --okx-title-margin: 2px 0;
  --okx-title-padding: 0;
  /* --okx-subtitle-color: var(--ion-color-primary); */
  --okx-subtitle-margin: 25px 0;
  --okx-subtitle-padding: 0;
  /* --okx-sectiontitle-color: var(--ion-color-primary); */
  --okx-sectiontitle-margin: 20px 0;
  --okx-sectiontitle-padding: 0;
  --okx-error-margin: 0 0 10px;
  --okx-hr-margin: 20px auto;
  --okx-border-color: var(
    --ion-item-border-color,
    var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
  );
  --okx-drawer-padding: 32px;
  --okx-box-radius: 10px;
  --okx-box-padding-left: 15px;
  --okx-box-padding-top: 15px;
  --okx-box-padding-right: 15px;
  --okx-box-padding-bottom: 15px;
  --okx-small-radius: 5px;
  /* --okx-normal-text-color: var(--ion-text-color); */
  --okx-circle-color: var(--ion-circle-color);
  --okx-incrementer-button-width: 40px;
  --okx-loyalty-badge-width: 40px;
  --okx-loyalty-badge-inner-padding: 4px;
  --okx-loyalty-bar-height: 50px;
  --okx-color-border-dash: #2d2b2b;
  --okx-cancel-button-color: rgba(255, 255, 255, 0.2);
  --okx-scrollbar-handle-color: var(--ion-color-secondary);
  --okx-scrollbar-track-color: var(--ion-color-light);
  --okx-scrollbar-color: var(--okx-scrollbar-handle-color) var(--okx-scrollbar-track-color);
  --okx-color-black: #000;
  --okx-small-button-width: 60%;
  --bullet-background: var(--ion-color-primary);
  --bullet-background-active: var(--ion-color-primary);
  --safe-area-inset-top: env(safe-area-inset-top)
}

.web {
  --ion-item-border-color: #000;
  --okx-bsmodal-top: 180px;
  --okx-bsmodal-width-lg: 500px;
  --okx-bsmodal-width-md: 420px;
  --okx-bsmodal-width-sm: 390px;
}

@media screen and (min-width: 1400px) and (orientation: portrait) {
  :root {
    --okx-title-font-size: 50px;
    --ion-font-size: 30px;
    --okx-subtitle-font-size: 45px;
    --okx-font-size: var(--ion-font-size);
    --okx-small-text-font-size: 28px;
    --ion-padding:40px

  }
  .sidebar-link{
    font-size: 35px !important;
  }
  .sidebar-user{
    font-size: 30px !important;
  }
  ion-toolbar.primary-toolbar {
    --min-height: 200px;
  }
  ion-toolbar.primary-toolbar .image-button {
    height: 100px;
  }
 .web .ion-padding, .web #main > ion-content.route-history > .no-padding, .web .ion-padding, .web #main > ion-content.route-history > .no-padding, .web .raf-card, .web .restaurant-card, .web .delivery-options-card, .web #main > ion-content.route-loyalty > .no-padding, .web .click-collect-layout{
    width: 700px !important;
  }
  .web ion-modal.modal-classic{
    --width:700px
  }
}
@media screen and (min-width: 2200px) and (orientation: landscape) {
  :root {
    --okx-title-font-size: 45px;
    --ion-font-size: 28px;
    --okx-subtitle-font-size: 40px;
    --okx-font-size: var(--ion-font-size);
    --okx-small-text-font-size: 25px;
    --ion-padding:40px;
    --okx-drawer-max-width:400px;
    --okx-sidebar-width: 500px;
  }
  .sidebar-link{
    font-size: 35px !important;
  }
  .sidebar-user{
    font-size: 30px !important;
  }
  ion-toolbar.primary-toolbar {
    --min-height: 180px;
  }
  ion-toolbar.primary-toolbar .image-button {
    height: 100px;
  }
  .web .ion-padding, .web #main > ion-content.route-history > .no-padding, .web .ion-padding, .web #main > ion-content.route-history > .no-padding, .web .raf-card, .web .restaurant-card, .web .delivery-options-card, .web #main > ion-content.route-loyalty > .no-padding, .web .click-collect-layout{
    width: 800px !important;
  }
  .web ion-modal.modal-classic{
    --width:800px
  }
}
