.text-box {
  display: flex;
  justify-content: center;
  height: 40px;
  /* background: green; */
  align-items: center;
  font-weight: bolder;
}

.order-paid-status {
  padding: 5px 20px;
  background-color: var(--ion-color-secondary);
  color: var(--ion-color-secondary-contrast);
  border-radius: 15px;
  width: max-content;
  margin-top: 5px;
}
