.route-account ion-slides * {
  font-family: inherit !important;
}
.account-inputs {
  padding-bottom: 30px;
  border-bottom: 1px solid var(--ion-color-light);
}
.account-actions {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.account-actions .normal-text {
  display: block;
  margin-bottom: 10px;
  color: var(--ion-color-secondary);
  cursor: pointer;
}
.arrow-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  cursor: pointer;
}
.arrow-button .normal-text {
  color: var(--ion-color-secondary);
}
.toggle-item {
  display: grid;
  grid-template-columns: 80% max-content;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}
.app-version {
  position: relative;
  bottom: 0px;
}
.comunication-preferences-block {
  display: flex;
  justify-content: center;
}
.comunication-preferences-block .normal-text {
  width: 300px;
}
