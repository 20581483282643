.mbsc-ios .mbsc-fr-btn {
  color: var(--ion-color-primary);
}

.mbsc-ios .mbsc-sc-whl-o,
.mbsc-ios.mbsc-calendar .mbsc-sc-whl-o {
  background: transparent !important;
}
.mbsc-ios .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c {
  visibility: hidden;
}
.mbsc-ios .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c,
.mbsc-ios.mbsc-calendar .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c {
  background-color: transparent !important;
}
.mbsc-ios .mbsc-sc-whl-l{
  background-color: var(--ion-color-light);
  border: none !important;
}
.mbsc-ios.mbsc-fr-inline .mbsc-fr-c{
  border: none;
}
.mbsc-ios.mbsc-fr-inline .mbsc-fr-w,
.mbsc-fr-c,
.mbsc-fr-btn-cont {
  background-color: var(--okx-background-color) !important;
}
.mbsc-ios .mbsc-sc-itm-sel {
  color: var(--ion-color-primary) !important;
}
.mbsc-ios .mbsc-sc-itm-sel,
.mbsc-ios .mbsc-sc-whl-gr-3d .mbsc-sc-itm {
  color: var(--ion-color-gray);
  font-size: 20px;
  width: 100%;
}
.mbsc-ios .mbsc-sc-whl-l {
  border-top: 1px solid var(--ion-color-gray);
  border-bottom: 1px solid var(--ion-color-gray);
}
.select-picker-input--feedback + input.mbsc-select-input.mbsc-control {
  background-color: transparent;
}
.mbsc-ios.mbsc-fr-center .mbsc-fr-btn-w {
  border: none;
}
.mbsc-select-input.mbsc-control {
  width: 100%;
  background: transparent;
  color: var(--ion-color-dark);
  padding: 10px 8px 10px 0;
  border: 0 none;
  border-color: var(
    --ion-item-border-color,
    var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
  );
  outline: none;
  text-transform: capitalize;
}

/* .mbsc-select-input.mbsc-control {
  width: 100%;
  color: var(--ion-color-primary);
  background-color: transparent;
  padding: 10px 8px 10px 0;
  border: 0;
  border-bottom: 1px solid;
  border-color: var(
    --ion-item-border-color,
    var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
  );
  outline: none;
}
.mbsc-ios .mbsc-sc-whl-o, .mbsc-ios.mbsc-calendar .mbsc-sc-whl-o{
  background:transparent !important
}
.mbsc-select-input.mbsc-control::placeholder {
  color: var(--ion-color-dark);
  opacity: 0.33;
}

.mbsc-ios .mbsc-fr-btn {
  color: var(--ion-color-primary);
  font-size: 17px;
}

.select-picker-label {
  font-size: var(--okx-default-font-size);
  color: var(--ion-color-primary);
  margin-top: 10px;
}

.select-picker-label--primary {
  color: var(--ion-color-primary, #3880ff);
}

.select-picker-label.select-picker-label--feedback {
  margin-left: 0;
}

.select-picker-input--feedback + input.mbsc-select-input.mbsc-control {
  color: var(--ion-color-primary);
  font-size: 10px;
  font-weight: normal;
  border-color: var(
    --ion-color-black,
    var(--ion-color-black, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
  );
}

.mbsc-fr-btn-w .mbsc-fr-btn-s {
  border: 0;
}

.mbsc-ios.mbsc-fr-center .mbsc-fr-btn-w {
  border: 0;
}

.mbsc-ios.mbsc-fr-center .mbsc-fr-btn-cont {
  border: 1px solid
    var(
      --ion-item-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
    );
}

.mbsc-ios .mbsc-sc-whl-gr-3d .mbsc-sc-itm-3d {
  color: #a3a3a3;
}

.mbsc-ios .mbsc-sc-itm-sel {
  color: #000;
  color: var(--ion-color-primary, #3880ff) !important;
} */

.mbsc-sc-itm-3d {
  width: unset;
}
