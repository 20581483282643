.route-history-details .order-header-content-wrapper{
  margin: 0;
}

.web .route-history-details .page-secondary-header{
  display: block;
  top: var(--ion-padding);
}
.web .route-history-details .page-secondary-header::after{
 display: none;
}

.web .route-history-details .page-secondary-header + *{
  margin-top: 60px;
}
.route-history-details .absolute-content{
  padding: 0;
}
.route-history-details .absolute-content .scrollable-y{
  padding: var(--ion-padding)
}