.comment-wrapper-light {
  background: var(--ion-color-white, #ffffff);
  border-radius: var(--okx-box-radius);
  padding: var(--ion-padding);
}

.student-button {
  margin-top: var(--ion-padding);
  width: 100%;
}
