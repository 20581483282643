.route-table .tabs {
  top: 150px;
}
.table-header {
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 var(--ion-padding);
}
.table-header::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  height: 2px;
  width: 100%;
  height: 5px;
  background: url(../../assets/images/dash.svg) 50% / auto 100% repeat !important;
  background-repeat: repeat-x;
}
.table-header .normal-text {
  color: #9b9b9b;
}
.table-card,
.redeem-voucher-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table-card {
  margin-bottom: 10px;
}
.table-content {
  text-align: start;
}
.table-name {
  font-size: calc(var(--ion-font-size) + 3px);
  color: #000000;
}
.table-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.table-actions ion-button {
  margin: 0 !important;
  font-size: 10px;
  clip-path: none;
  font-family: franco-manca-bold !important;
}
.table-actions img {
  height: 40px;
  width: 40px;
  display: block;
  margin-left: 5px;
}
.table-vouchers-modal {
  --height: 100vh !important;
  --width: 100% !important;
}
.table-vouchers-modal .modal-wrapper {
  border-radius: 0 !important;
}
.redeem-voucher-wrapper ion-input {
  background-color: #d3d3d3;
  color: var(--ion-color-gray);
  text-align: center;
  text-transform: uppercase;
}
.redeem-voucher-wrapper ion-item {
  flex: 1;
  margin-right: 10px;
}
.redeem-voucher-wrapper ion-button {
  margin: 0 !important;
  font-size: 12px;
  clip-path: none;
  flex: 0;
}
.table-actions ion-button.circle-btn {
  height: 40px;
  width: 40px;
  --border-radius: 50%;
  margin-left: 10px !important;
}
.table-vouchers-modal .table-card {
  margin-bottom: 10px;
}
.absolute-content.table-data-content {
  padding: 0 !important;
}
.active-user-card {
  padding-bottom: 30px;
  border-bottom: 1px solid var(--ion-color-light);
}

.route-table ion-segment-button {
  top: -3px;
}

.route-table ion-segment-button:not(:last-child)::before{
  height: 95%;
}