.splited-input-wrapper {
  display: flex;
}

.splited-input {
  background: #fff;
  font-size: var(--okx-subtitle-font-size, 26px);
  margin-bottom: 20px;
  text-align: center;
  margin: 4px;
  border: 1px solid var(--ion-color-gray);
}

.splited-input .native-input {
  padding-left: 0px;
  padding-right: 0px;
}
.spliter-char {
  display: flex;
  align-content: center;
  align-items: center;
  font-size: var(--okx-subtitle-font-size, 26px);
}
