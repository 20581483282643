.code-wrapper {
  display: flex;
}
.code-wrapper ion-item {
  border: 1px solid var(--ion-color-gray);
  margin: 0 5px;
  padding: 0;
  height: 70px;
  font-size: 45px;
}
.code-wrapper input {
  padding: 10px !important;
  height: 70px;
}

.web .route-send-code .page-secondary-header{
  display: block;
  top: 0
}
.web .route-send-code .page-secondary-header::after{
 display: none;
}

.web .route-send-code .page-secondary-header + *{
  margin-top: 60px;
}
