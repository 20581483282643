.offline-banner {
  position: sticky;
  display: grid;
  top: 50%;
}

.offline-banner > ion-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: franco-manca-bold !important;
  width: 100%;
  height: 40px;
  padding: 20px;
  background-color: var(--ion-color-secondary);
}
