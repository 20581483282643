.incrementer {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.route-apply-points .incrementer,
.apply-points-modal .incrementer {
	display: grid;
	grid-template-columns: 35px auto 35px;
	grid-template-areas:
		'decrease points increase'
		'note note note';
}
.incrementer-disabled{
	pointer-events: none;
	--background: var(--ion-color-light) !important;
	--border-color:var(--ion-color-light) !important;
}
.incrementer-decrease,
.incrementer-incease {
	height: var(--okx-incrementer-button-width);
	--padding-start: 0;
	--padding-end: 0;
	--border-width: 1px;
  --border-radius:50%;
	--border-style: solid;
	--border-color: var(--ion-color-secondary);
	--background: #fff;
	--background-hover: transparent;
	--color: var(--ion-color-white);
  --background:var(--ion-color-secondary);
	margin: 0;
  height: 24px !important;
  width: 24px !important;
  min-height: 24px !important;
}

.route-apply-points .incrementer-decrease,
.apply-points-modal .incrementer-decrease {
	grid-area: decrease;
}

.route-apply-points .incrementer-incease,
.apply-points-modal .incrementer-incease {
	grid-area: increase;
}

.incrementer-quantity {
	padding-left: 10px;
	padding-right: 10px;
}

.incrementer-quantity-solo {
	flex: 1 1 auto;
	text-align: center;
	font-weight: bold;
  margin: 0 10px;
}

.incrementer-note {
	font-size: var(--okx-small-text-font-size);
	color: var(--okx-color-dark);
	text-align: center;
}

.route-apply-points .incrementer-note,
.apply-points-modal .incrementer-note {
	grid-area: note;
	margin-top: 10px;
}

.route-apply-points .incrementer-quantity-value,
.apply-points-modal .incrementer-quantity-value {
	font-size: 46px;
}

.web ion-modal.modal-classic.quantity-modal .incrementer {
	display: grid;
	grid-template-columns: 35px auto 35px;
	grid-template-rows: 35px;
	margin: auto 0;
}


.incrementer{
  flex:unset !important;
    padding: 7px;
    border-radius: 25px;
    border: 1px solid var(--ion-color-light);
    height: 40px;
}
.item-content-actions .incrementer-quantity:not(.incrementer-quantity-solo) .incrementer-quantity-value {
	display: none;
}