.route-social .square {
  background-color: var(--ion-color-secondary);
  display: flex;
  position: relative;
  border-radius: var(--okx-box-radius);
  padding: 10px;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}
.route-social .square .subtitle {
  color: var(--ion-color-secondary-contrast);
}
.soc-icon {
  height: 40px;
  width: 40px;
  background-color: var(--ion-color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  border-radius: 5px;
}
.soc-icon ion-icon {
  height: 20px;
  width: 20px;
}
